<template>
  <div>
    <el-row style="margin-top: 20px">
      <el-col :span="12" :offset="6">
        <el-card class="box-card">
          <el-form ref="form" :model="formData" label-width="80px" style="margin-top: 20px">
            <el-form-item label="平台">
              <el-radio-group v-model="formData.pickData" size="small">
                <el-radio-button v-for="item in placeData" :label="item.value" :key="item.value">
                  {{ item.name }}
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <br>
            <div v-if="isText">
              <div v-for="(item,index) in typeData" :key="index">
                <el-form-item label="授权类型">
                  <el-input v-model="item.type" placeholder="视频授权/挂购物⻋佣⾦"></el-input>
                </el-form-item>
                <el-form-item label="类型数据">
                  <el-input v-model="item.data" placeholder="视频授权/挂购物⻋佣⾦"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button style="float:right;" @click="subtractChildBtn(index)">删除当前项</el-button>
                </el-form-item>
              </div>
            </div>
            <div v-else>
              <div v-for="(item,index) in priceData" :key="index">
                <el-form-item label="授权类型">
                  <el-input v-model="item.type" placeholder="视频授权/挂购物⻋佣⾦"></el-input>
                </el-form-item>
                <el-form-item label="档位" v-for="(e,i) in item.data" :key="index+i">
                  <el-row>
                    <el-col :span="5">
                      <el-select v-model="e.label" placeholder="请选择" @change="changeGame">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12" :offset="1">
                      <el-input placeholder="视频授权/挂购物⻋佣⾦" @change="changeGame" v-model="e.value"></el-input>
                    </el-col>

                    <el-col :span="2" :offset="1">
                      <el-button @click="addPlace(index)">增加档位</el-button>
                    </el-col>
                    <el-col :span="2" :offset="1">
                      <el-button @click="subtractPlace(index,i)">删除</el-button>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item>
                  <el-button style="float:right;" @click="subtractChildBtn(index)">删除当前项</el-button>
                </el-form-item>
              </div>

            </div>
<!--            <el-form-item>-->
<!--              <el-button @click="addChildBtn">增加一条</el-button>-->
<!--            </el-form-item>-->
            <el-form-item>
              <el-button @click="addChildBtn">增加一条</el-button>

              <el-button type="primary" @click="submitForm">保存</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>

        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-col :span="12" :offset="6">
        <el-card class="box-card">
          <div v-for="(content,index) in chargingList" :key="content[index]" style="padding: 20px">
            <div v-for="(nex,key) in content.content" :key="key">
              <h3>平台：
                <span v-for="item in placeData" :key="item.value+item.name">
                                {{ item.value === key ? item.name : '' }}
                              </span></h3>
              <template v-if="dyPlace.includes(key)">
                <div v-for="item in nex" :key="item.type">
                  <h4>{{ item.type }} </h4>
                  {{ item.data }}
                </div>
              </template>
              <template v-else>
                <div v-for="item in nex" :key="item.type">
                  <h4>{{ item.type }} </h4>
                  <a v-for="(k,index) in item.data" :key="index">
                    <h5 v-for="j in options" :key="j.value">
                      {{ j.value == k.label ? j.label : '' }}
                    </h5>
                    {{ k.value }}
                  </a>
                </div>
              </template>
            </div>

          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>

</template>

<script>
import charJson from './index.json'

export default {
  name: 'chargingStandard',
  data() {
    return {
      formData: {
        pickData: 'douyin'
      },
      typeData: [
        {
          type: '',
          data: ''
        }
      ],
      priceData: [
        {
          type: '',
          data: [
            { value: '', label: '' }
          ]
        }
      ],
      placeData: [
        { name: '抖音', value: 'douyin' },
        { name: '小红书', value: 'xiaohongshu' },
        { name: '微博', value: 'weibo' },
        { name: '快手', value: 'kuaishou' }],
      options: [
        {
          value: '1000000',
          label: '大于等于1000000'
        },
        {
          value: '500000',
          label: '大于等于500000'
        },
        {
          value: '300000',
          label: '大于等于300000'
        },
        {
          value: '299999',
          label: '小于300000'
        }],
      dyPlace: ['douyin', 'kuaishou'],
      chargingList: {},
      content: {},
      charJson: charJson,
      checkData: ''
    }
  },
  computed: {
    isText() {
      return this.dyPlace.includes(this.formData.pickData)
    }
  },
  watch: {
    'formData.pickData'() {
      this.switchTab()
    }
  },
  methods: {

    changeGame() {
      this.$forceUpdate()
    },
    subtractChildBtn(index) {
      if (this.isText) {
        this.typeData.length !== 1 ?
            this.typeData.splice(index, 1)
            : this.$message.error('唯一不可删除')
      } else {
        this.priceData.length !== 1 ?
            this.priceData.splice(index - 1, 1)
            : this.$message.error('唯一不可删除')
      }
    },
    addChildBtn() {
      if (this.isText) {
        this.typeData.push({
          type: '',
          data: ''
        })
      } else
        this.priceData.push(
            {
              type: '',
              data: [
                { value: '', key: '' }
              ],
              datas: [
                { value: '', key: '' }
              ]
            }
        )
    },
    addPlace(index) {
      this.priceData[index].data.push({
        type: '',
        data: ''
      })
    },
    subtractPlace(index, i) {
      this.priceData[index].data.length !== 1 ?
          this.priceData[index].data.splice(i, 1)
          : this.$message.error('唯一不可删除')
    },
    submitData() {
      if (this.isText) {
        let newJson = {}
        let name = this.formData.pickData
        if (this.typeData[0].data === '' || this.typeData[0].type === '') {
          this.$message.error('请至少输入一条数据')
          return false
        } else {
          newJson = JSON.stringify({ [name]: this.typeData })
          return {
            title: 'test',
            sub_title: 122,
            content: newJson,
            intro: '说明',
            type: name
          }
        }
      } else {
        let newJson = {}
        let name = this.formData.pickData
        if (this.priceData[0].type === '') {
          this.$message.error('请至少输入一条数据')
          return false
        } else {
          this.priceData.forEach(item => {
            let newObjee = {}
            if (item.data.length > 0) {
              item.data.forEach(i => {
                newObjee[i.label] = i.value
              })
              item.data = newObjee
            } else this.$message.error('请至少输入一条数据')
          })
          newJson = JSON.stringify({ [name]: this.priceData })
          return {
            title: 'test',
            sub_title: 122,
            content: newJson,
            intro: '说明',
            type: name
          }
        }
      }
    },
    async submitForm() {
      let data = this.submitData()
      // console.log(data)
      // // content
      let id = await this.$api.saveCharging(data)
      if (id) {
        this.$notify.success('修改成功')
        this.checkData = this.formData.pickData
        this.getInfo()
      }
    }
    ,
    resetForm() {
      if (this.isText) {
        let charJson = JSON.parse(JSON.stringify(this.charJson[this.formData.pickData]))
        this.typeData = charJson
      } else {
        let charJson = JSON.parse(JSON.stringify(this.charJson[this.formData.pickData]))
        this.priceData = charJson
      }

    },
    async getInfo() {
      let { list } = await this.$api.chargingList()
      this.chargingList = list

      this.placeData.forEach(item => {
        this.formData.pickData = item.value
        this.switchTab()
      })
      if (this.checkData != '') {
        this.formData.pickData = this.checkData

      } else
        this.formData.pickData = 'douyin'
      this.switchTab()
    }
    ,
    switchTab() {
      this.chargingList.forEach((item) => {
        if (this.formData.pickData == item.type) {
          this.content = item.content
          if (this.isText) {
            this.typeData = item.content[this.formData.pickData]
          } else {
            this.priceData = item.content[this.formData.pickData]
          }
        }
      })
      if (!this.isText) {
        this.priceData.forEach((i) => {
          let newDatas = []
          let datas = {}
          if (i.data?.length > 0) {
            newDatas = i.data
          } else if (typeof i.data === 'object') {
            for (let key in i.data) {
              datas = {
                value: i.data[key], label: key
              }
              newDatas.push(datas)
            }
          }
          i.data = newDatas
        })
      }
    }
  }
  , mounted() {
    this.getInfo()
  }
}
</script>
